import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbDatepicker, NgbDatepickerNavigateEvent } from '@ng-bootstrap/ng-bootstrap';
import { CalendarDialogData, CalendarDialogResponse } from './calendar-data';
import { CalendarViewDto } from '../../../booking/booking-details/models/calendar-view.dto';
import { AvailableRoomDto } from '../../../booking/booking-details/models/available-room.dto';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent implements OnInit {
  @ViewChild('calendarDp') calendarDp!: NgbDatepicker;
  @Output() updateDate = new EventEmitter<{ year: number, month: number }>();

  currentDay = new Date();
  minDate = this.calendar.getToday();
  calendarModel!: NgbDateStruct;

  selectedDate!: NgbDate;
  selectedTime: string | null = null;
  selectedRoom: AvailableRoomDto | null = null;
  selectedBestLawyerId: string | null = null;

  lawyersCalendar: CalendarViewDto[] = [];
  avalibleTimes: CalendarViewDto[] = [];

  calendarLoading = false;

  constructor(
    public dialogRef: MatDialogRef<CalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarDialogData,
    public calendar: NgbCalendar
  ) { }

  ngOnInit() {
    if (this.data.slots)
      this.lawyersCalendar = this.data.slots;

    if (this.data.calendarSubject) {
      this.data.calendarSubject.subscribe((newSlots: CalendarViewDto[]) => {

        if (newSlots.length > 0) {
          this.lawyersCalendar = newSlots;
        }
        // filter the list by lawyer ID
        if (this.data.lawyerId) {
          this.lawyersCalendar = this.filterSlotsByLawyerId(this.lawyersCalendar, this.data.lawyerId);
        }

        this.calendarLoading = false;
      });
    }



    if (this.data.date) {
      this.setDateOnCalendar(this.data.date);

      let ngbDate = new NgbDate(this.data.date.getFullYear(), this.data.date.getMonth() + 1, this.data.date.getDate());
      this.onDateSelect(ngbDate);
    }

    if (this.data.time)
      this.selectedTime = this.data.time;

  }

  filterSlotsByLawyerId(
    lawyersCalendar: CalendarViewDto[],
    lawyerId: string
  ): CalendarViewDto[] {
    const filteredCalendar: CalendarViewDto[] = [];

    lawyersCalendar.forEach((calendar) => {
      const calendarCopy = { ...calendar };
      // Check if the calendar matches the selected lawyerId
      if (
        calendarCopy.bestLawyerId === lawyerId ||
        (calendarCopy.otherAvailableLawyersIds &&
          calendarCopy.otherAvailableLawyersIds.includes(lawyerId))
      ) {
        // Assign the selected lawyerId to the bestLawyerId
        calendarCopy.bestLawyerId = lawyerId;

        // Remove otherAvailableLawyerIds if they exist
        calendarCopy.otherAvailableLawyersIds = [];

        // Push the matched calendar item to the filtered array
        filteredCalendar.push(calendarCopy);
      }
    });

    return filteredCalendar;
  }



  convertToNgbDate(dateStr: string): NgbDate {
    const date = new Date(dateStr);
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  isAvailable(date: NgbDate): boolean {
    return this.lawyersCalendar.some(calendar => {
      const startTime = this.convertToNgbDate(calendar.slot.startTime);
      const endTime = this.convertToNgbDate(calendar.slot.endTime);
      return date.equals(startTime) || (date.after(startTime) && date.before(endTime)) || date.equals(endTime);
    });
  }

  prepareTimeSlots(date: NgbDate) {
    const selectedDate = new Date(date.year, date.month - 1, date.day);

    this.avalibleTimes = this.lawyersCalendar
      .filter(calendar => {
        const startTime = new Date(calendar.slot.startTime);
        const endTime = new Date(calendar.slot.endTime);
        return startTime.toDateString() === selectedDate.toDateString() && endTime.toDateString() === selectedDate.toDateString();
      });
  }

  isDisabled = (date: NgbDate, current: { month: number; year: number }) => date.month !== current.month;
  isWeekend = (date: NgbDate) => this.calendar.getWeekday(date) >= 6;
  isToday = (date: NgbDate) => {
    return date.day == this.currentDay.getDate() &&
      date.month == this.currentDay.getMonth() + 1 &&
      date.year == this.currentDay.getFullYear();
  }
  goToToday(): void {
    this.calendarModel = this.calendar.getToday();
    this.calendarDp.navigateTo();
  }

  parseNgbDateToDate(ngbDate: NgbDateStruct): Date | null {
    if (!ngbDate) {
      return null;
    }
    // Month index is 0-based in JavaScript Date, so subtract 1
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  setDateOnCalendar(date: Date) {
    this.calendarModel = {
      year: date.getFullYear(),
      month: date.getMonth() + 1, // NgbDateStruct months are 1-based
      day: date.getDate()
    };
  }

  converStringDateToTimeOnly(date: string) {
    let dateFormat = new Date(date);
    return dateFormat.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
  }

  onCalendarNavigate(event: NgbDatepickerNavigateEvent) {
    let calendarYear = event.next.year;
    let calendarMonth = event.next.month;

    this.updateDate.emit({ year: calendarYear, month: calendarMonth });
    this.calendarLoading = true;
  }

  onDateSelect(date: NgbDate) {
    this.selectedDate = date;

    this.selectedTime = null;
    this.prepareTimeSlots(date);
  }

  onTimeSelect(timeSlot: CalendarViewDto) {
    this.selectedTime = this.converStringDateToTimeOnly(timeSlot.slot.startTime) + '-' + this.converStringDateToTimeOnly(timeSlot.slot.endTime);
    this.selectedBestLawyerId = timeSlot.bestLawyerId;

    this.getFirstAvalibleRoomBaseOnTimeSlot(timeSlot.slot.startTime);
  }

  isTimeSelected(startDate: string) {
    return this.converStringDateToTimeOnly(startDate) == this.selectedTime?.split('-')[0];
  }

  getFirstAvalibleRoomBaseOnTimeSlot(startTime: string) {
    let selectedSlotFromLawyersCalendar = this.lawyersCalendar.find(slot => slot.slot.startTime === startTime);
    let availableRooms = selectedSlotFromLawyersCalendar?.availableRooms;
    if (availableRooms && availableRooms?.length > 0)
      this.selectedRoom = availableRooms[0];
  }


  save() {
    let datatime: CalendarDialogResponse = {
      date: this.parseNgbDateToDate(this.calendarModel),
      time: this.selectedTime,
      room: this.selectedRoom,
      bestLawyerId: this.selectedBestLawyerId
    }
    this.dialogRef.close(datatime);
  }

  close() {
    this.dialogRef.close();
  }
}
